var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('event-page', [!_vm.$route.params._exhibition ? _c('exhibition-list') : _c('exhibition-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }