<template>
    <div>
        <v-row>
            <v-col v-for="(item, index) in items" :key="index" cols="12">
                <v-card :to="`${$route.path}/${item._id}`" class="event-card">
                    <v-img cover :src="item?.thumb?.url" :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1330 / 340 : 300 / 180">
                        <div class="d-flex align-center h-100 pa-20px py-md-30px px-lg-60px" style="background-color: rgba(0, 0, 0, 0.5)">
                            <div class="white--text">
                                <v-chip v-if="isOngoing" x-small v-bind="{ ...chip_secondary2 }">진행중</v-chip>
                                <v-chip v-else x-small v-bind="{ ...chip_secondary }">종료</v-chip>
                                <div class="tit tit--lg mt-4px mt-md-8px">{{ item?.title }}</div>
                                <div class="font-size-14 font-size-md-16 mt-6px mt-md-12px" v-if="item?.duration[0] || item?.duration[1]">{{ formatDate(item?.duration[0]) }} ~ {{ formatDate(item?.duration[1]) }}</div>
                            </div>
                        </div>
                    </v-img>

                    <div v-if="!isOngoing" class="event-card__overlay">
                        <div class="tit white--text">이벤트가 종료되었습니다.</div>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <div class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { EVENT_STATUS, chip_secondary, chip_secondary2 } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import Snb from "@/components/client/list/snb.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        Snb,
    },
    props: {
        status: { type: String, default: EVENT_STATUS.ONGOING.value },
    },
    data: () => ({
        chip_secondary,
        chip_secondary2,
        EVENT_STATUS,

        events: [],

        limit: 5,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.events];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.group = this.group;
            return { ...query };
        },
        isOngoing() {
            return this.status === EVENT_STATUS.ONGOING.value;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, events } = await api.v1.shop.events.gets({
                    headers: { skip, limit },
                    params: { ...params, status: this.status, shows: true },
                });

                this.summary = summary;
                this.events = events.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } finally {
                this.loading = false;
            }
        },
        formatDate(date) {
            return date ? this.$dayjs(date).format("YYYY-MM-DD") : "";
        },
    },
};
</script>

<style lang="scss" scoped>
.event-card {
    position: relative;
    overflow: hidden;
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>