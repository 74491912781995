var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual-primary', {
          attrs: {
            "tit": "Event",
            "bg": "/images/sub/event/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "path",
            fn: function () {
              return [_c('visual-breadcrumbs')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "containerSize": "container--lg"
    }
  }, [_c('v-row', {
    staticClass: "flex-xl-nowrap"
  }, [_vm.$vuetify.breakpoint.xlOnly ? _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('div', {
    staticClass: "w-xl-200px"
  }, [_c('snb', {
    attrs: {
      "title": "Event",
      "items": _vm.snbItems
    },
    model: {
      value: _vm.activeItem,
      callback: function ($$v) {
        _vm.activeItem = $$v;
      },
      expression: "activeItem"
    }
  })], 1)]) : _vm._e(), _c('v-col', [_vm._t("default")], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }