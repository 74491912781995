<template>
    <div class="snb">
        <h2 class="tit tit--xs">
            {{ title }}
        </h2>
        <v-list flat no-action>
            <template v-for="(item, index) of computedItems">
                <v-list-group v-if="item.children" :value="item.active" :key="index">
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title class="txt txt--lg txt--dark font-weight-bold">
                                {{ item.title }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <template v-for="(child, childIndex) of item.children">
                        <v-list-group v-if="child.children" :value="child.active" :key="childIndex" sub-group>
                            <template #activator>
                                <v-list-item-title>
                                    {{ child.title }}
                                </v-list-item-title>
                            </template>
                            <template v-for="(grand, grandIndex) of child.children">
                                <!-- <v-list-item :to="grand.to" :class="{ active: child.active }" :key="grandIndex"> -->
                                <v-list-item :class="{ active: grand.active }" :key="grandIndex" :to="grand.to">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ grand.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list-group>

                        <v-list-item v-else :value="child.active" :key="childIndex" :to="child.to">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>

                <v-list-item v-else :to="item.to" :class="{ active: item.active }" :key="index">
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </div>
</template>

<script>
// const items = [
//     {
//         title: String,
//         to: { path: String, query: {} }
//     },
//     {
//         title: String,
//         children: [
//             {
//                 title: String,
//                 to: { path: String, query: {} }
//             },
//         ]
//     }
// ]

export default {
    props: {
        title: { type: String, default: "Category" },
        items: { type: Array, default: () => [] },
    },
    computed: {
        computedItems() {
            return this.items.map(
                function activeMapper(item) {
                    item = {
                        ...item,
                        children: item.children?.map(activeMapper.bind(this)),
                    };

                    item.active = item?.children?.some?.(({ active }) => active) || this.$route?.path.split("/").includes(item?.to?.path?.split?.("/")?.at?.(-1));

                    if (!item.children) delete item.children;
                    return item;
                }.bind(this)
            );
        },
        activeItem() {
            let item = this.computedItems.find(({ active }) => active);
            if (!item) {
                let children = [...this.computedItems.map(({ children }) => children)].filter((item) => item).flat();
                item = children.find(({ active }) => active);
            }

            return item;
        },
    },
    mounted() {
        this.emitActiveItem();
    },
    watch: {
        activeItem() {
            this.emitActiveItem();
        },
    },
    methods: {
        emitActiveItem() {
            this.$emit("input", this.activeItem);
        },
    },
};
</script>

<style lang="scss" scoped>
.snb {
    transform: translateY(-40px);

    .v-list {
        margin-top: 12px;
        padding: 20px 0;
        border-top: 3px solid var(--v-primary-base);
        // > .v-list-item,
        // > .v-list-group {
        //     margin-bottom: 30px;
        //     &:last-child {
        //         margin-bottom: 0;
        //     }
        // }
    }
}

::v-deep() {
    .v-list-group--sub-group .v-list-group__header,
    .v-list-item {
        padding: 0;

        &::before {
            background-color: initial;
        }

        &--active {
            .v-list-item__title {
                font-weight: 700;
            }
        }
    }
    .active .v-list-item__title {
        font-weight: 700;
    }
    .v-list-group--sub-group {
        .v-list-item__icon:first-child {
            order: 1;
            margin-right: 0;
        }
        .v-list-group__items .v-list-item {
            padding-left: var(--container-gutter);
        }
    }
}
</style>