<template>
    <event-page>
        <mypage-coupons-down />
    </event-page>
</template>

<script>
import EventPage from "@/components/client/templates/event-page.vue";

import MypageCouponsDown from "@/components/client/mypage/coupons/mypage-coupons-down.vue";

export default {
    components: {
        EventPage,

        MypageCouponsDown,

    },
};
</script>
