<template>
    <v-sheet>
        <page-section class="page-section--first pb-0">
            <v-img :src="exhibition?.photo?.url"></v-img>
            <!-- <v-card :img="exhibition?.photo?.url">
                <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1330 / 500 : 300 / 180" content-class="d-flex justify-center align-center">
                    <v-overlay absolute z-index="1" />
                    <div class="px-12px py-30px pa-md-40px py-lg-60px text-center white--text position-relative z-1">
                        <div class="mb-8px">
                            <v-chip v-if="exhibition?.label" color="secondary" class="white--text"> {{ exhibition?.label }} </v-chip>
                        </div>
                        <div v-if="exhibition?.title" class="tit tit--lg mb-8px mb-md-12px">{{ exhibition?.title }}</div>
                        <div class="txt txt--sm white--text" v-if="exhibition?.duration[0] || exhibition?.duration[1]">
                                    {{ exhibition?.duration[0] ? $dayjs(exhibition?.duration[0]).format("YYYY-MM-DD") : "" }} ~ {{ exhibition?.duration[1] ? $dayjs(exhibition?.duration[1]).format("YYYY-MM-DD") : "" }}
                        </div>
                        <div v-if="mainProducts.length" class="pt-20px pt-md-40px">
                            <v-row justify="center">
                                <v-col v-for="product in mainProducts" :key="product._id" cols="auto">
                                    <v-card flat :to="`/shop/products/${product._id}`">
                                        <v-img cover width="120" height="120" class="grey" :src="product?.thumb?.url">
                                            <v-fade-transition>
                                                <v-overlay v-show="!product?.thumb?.url" absolute>
                                                    <v-icon>mdi-image-broken</v-icon>
                                                </v-overlay>
                                            </v-fade-transition>
                                        </v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-responsive>
            </v-card> -->
        </page-section>

        <page-section class="page-section--sm page-section--last">
            <v-row class="product-items">
                <shop-product-item v-for="item in items" :key="item._id" :value="item" />
            </v-row>

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </page-section>
    </v-sheet>
</template>

<script>
import api from "@/api";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductItem,
    },
    data: () => ({
        exhibition: undefined,
        products: [],

        limit: 10,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.products];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil((this.exhibition?._products?.length || 0) / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            let _id = [...(this.exhibition?._products || [])].splice((this.page - 1) * this.limit || 0, this.page * this.limit);
            return { ...query, _id };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            if(this.exhibition?._products?.length){
                this.search();
            }
        },
    },
    methods: {
        async init() {
            try {
                this.exhibition = (await api.v1.shop.exhibitions.get({ _id: this.$route.params._exhibition }))?.exhibition;
                if(this.exhibition?._products?.length) this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { params } = this;
                var { products } = await api.v1.shop.products.gets({
                    params,
                });

                this.products = products;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>
