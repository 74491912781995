var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "snb"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-list', {
    attrs: {
      "flat": "",
      "no-action": ""
    }
  }, [_vm._l(_vm.computedItems, function (item, index) {
    return [item.children ? _c('v-list-group', {
      key: index,
      attrs: {
        "value": item.active
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "txt txt--lg txt--dark font-weight-bold"
          }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_vm._l(item.children, function (child, childIndex) {
      return [child.children ? _c('v-list-group', {
        key: childIndex,
        attrs: {
          "value": child.active,
          "sub-group": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function () {
            return [_c('v-list-item-title', [_vm._v(" " + _vm._s(child.title) + " ")])];
          },
          proxy: true
        }], null, true)
      }, [_vm._l(child.children, function (grand, grandIndex) {
        return [_c('v-list-item', {
          key: grandIndex,
          class: {
            active: grand.active
          },
          attrs: {
            "to": grand.to
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(grand.title) + " ")])], 1)], 1)];
      })], 2) : _c('v-list-item', {
        key: childIndex,
        attrs: {
          "value": child.active,
          "to": child.to
        }
      }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(child.title) + " ")])], 1)], 1)];
    })], 2) : _c('v-list-item', {
      key: index,
      class: {
        active: item.active
      },
      attrs: {
        "to": item.to
      }
    }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.title) + " ")])], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }