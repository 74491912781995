var render = function render(){
  var _vm$exhibition, _vm$exhibition$photo;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('page-section', {
    staticClass: "page-section--first pb-0"
  }, [_c('v-img', {
    attrs: {
      "src": (_vm$exhibition = _vm.exhibition) === null || _vm$exhibition === void 0 ? void 0 : (_vm$exhibition$photo = _vm$exhibition.photo) === null || _vm$exhibition$photo === void 0 ? void 0 : _vm$exhibition$photo.url
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--sm page-section--last"
  }, [_c('v-row', {
    staticClass: "product-items"
  }, _vm._l(_vm.items, function (item) {
    return _c('shop-product-item', {
      key: item._id,
      attrs: {
        "value": item
      }
    });
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }