<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="Event" bg="/images/sub/event/visual.jpg">
                <template #path>
                    <visual-breadcrumbs />
                </template>
            </sub-visual-primary>
        </template>

        <page-section class="page-section--first page-section--last" containerSize="container--lg">
            <!-- <v-row class="row--contents flex-xl-nowrap"> -->
            <v-row class="flex-xl-nowrap">
                <v-col v-if="$vuetify.breakpoint.xlOnly" cols="12" xl="auto">
                    <div class="w-xl-200px">
                        <!-- PC -->
                        <snb v-model="activeItem" title="Event" :items="snbItems" />
                    </div>
                </v-col>
                <v-col>
                    <slot />
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import VisualBreadcrumbs from '@/components/client/inc/visual-breadcrumbs.vue';
import Snb from "@/components/client/list/snb.vue";

const snbItems = [
    {
        title: "진행중인 이벤트",
        to: { path: "/sub/event/ongoing" },
    },
    {
        title: "종료된 이벤트",
        to: { path: "/sub/event/ended" },
    },
    {
        title: "태그 이벤트",
        to: { path: "/sub/event/tag" },
    },
    {
        title: "기획전",
        to: { path: "/sub/event/exhibition" },
    },
    {
        title: "쿠폰",
        to: { path: "/sub/event/coupon" },
    },
];

export default {
    components: {
        ClientPage,
        PageSection,
        SubVisualPrimary,
        VisualBreadcrumbs,
        Snb,
    },
    props: {
        status: { type: String, default: null },
        mode: { type: String, default: "ongoing" },
    },
    data: () => ({
        snbItems,
        activeItem: undefined,
    }),
    computed: {
    },
};
</script>