<template>
    <event-page>
        <exhibition-list v-if="!$route.params._exhibition"></exhibition-list>
        <exhibition-view v-else></exhibition-view>
    </event-page>
</template>

<script>
import EventPage from "@/components/client/templates/event-page.vue";

import ExhibitionList from "@/components/client/sub/event/exhibition/exhibition-list.vue";
import ExhibitionView from "@/components/client/sub/event/exhibition/exhibition-view.vue";

export default {
    components: {
        EventPage,

        ExhibitionList,
        ExhibitionView,
    },
};
</script>
