var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('event-page', [_c('v-img', {
    staticClass: "rounded-lg d-flex text-center align-center",
    attrs: {
      "src": "/images/sub/event/tag-event.jpg"
    }
  }, [_c('span', {
    staticClass: "tit tit--lg white--text"
  }, [_vm._v("모아고 추천상품")])]), _c('div', {
    staticClass: "product-lists mt-40px mt-lg-60px"
  }, [_c('div', {
    staticClass: "product-lists-body"
  }, [_c('shop-product-items', _vm._l(_vm.tags, function (tag, index) {
    return _c('shop-product-item', {
      key: index,
      attrs: {
        "value": tag.product,
        "lg": "3"
      },
      scopedSlots: _vm._u([{
        key: "tag",
        fn: function () {
          return [_c('v-sheet', {
            staticClass: "pb-6px pb-md-8px txt txt--sm txt--dark text-center"
          }, [_vm._v(" " + _vm._s(_vm.$dayjs(tag.startsAt).format("YYYY-MM-DD")) + " ~ " + _vm._s(_vm.$dayjs(tag.endsAt).format("YYYY-MM-DD")) + " ")])];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }