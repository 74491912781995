<template>
    <event-page>
        <event-list v-if="!$route.params._event" v-bind="{ status }"></event-list>
        <event-view v-else v-bind="{ status }"></event-view>
    </event-page>
</template>

<script>
import { EVENT_STATUS } from "@/assets/variables";

import EventPage from "@/components/client/templates/event-page.vue";

import EventList from "@/components/client/sub/event/event/eventList.vue";
import EventView from "@/components/client/sub/event/event/eventView.vue";

export default {
    components: {
        EventPage,

        EventList,
        EventView, 
    },
    props: {
        status: { type: String, default: EVENT_STATUS.ONGOING.value },
        mode: { type: String, default: "ongoing" },
    },
};
</script>
