<template>
    <v-sheet>
        <v-row>
            <v-col v-for="(item, index) in items" :key="index" cols="12">
                <v-card :to="`${$route.path}/${item._id}`">
                    <v-img cover :src="item?.thumb?.url" :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1330 / 340 : 300 / 180">
                        <div class="d-flex align-center h-100 pa-20px py-md-30px px-lg-60px" style="background-color: rgba(0, 0, 0, 0.5)">
                            <div class="white--text">
                                <v-chip x-small v-bind="{ ...chip_secondary2 }">{{ item?.label }}</v-chip>
                                <div class="tit tit--lg mt-4px mt-md-8px">{{ item?.title }}</div>
                                <div class="font-size-14 font-size-md-16 mt-6px mt-md-12px" v-if="item?.duration[0] || item?.duration[1]">{{ formatDate(item?.duration[0]) }} ~ {{ formatDate(item?.duration[1]) }}</div>
                            </div>
                        </div>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>

        <div class="pagination-wrap">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { chip_secondary2 } from "@/assets/variables";

export default {
    components: {
    },
    data: () => ({
        chip_secondary2,

        exhibitions: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.exhibitions];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, exhibitions } = await api.v1.shop.exhibitions.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.exhibitions = exhibitions.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } finally {
                this.loading = false;
            }
        },
        formatDate(date) {
            return date ? this.$dayjs(date).format("YYYY-MM-DD") : "";
        },
    },
};
</script>
