var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('event-page', [!_vm.$route.params._event ? _c('event-list', _vm._b({}, 'event-list', {
    status: _vm.status
  }, false)) : _c('event-view', _vm._b({}, 'event-view', {
    status: _vm.status
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }