var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', _vm._l(_vm.items, function (item, index) {
    var _item$thumb;
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "event-card",
      attrs: {
        "to": `${_vm.$route.path}/${item._id}`
      }
    }, [_c('v-img', {
      attrs: {
        "cover": "",
        "src": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url,
        "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 1330 / 340 : 300 / 180
      }
    }, [_c('div', {
      staticClass: "d-flex align-center h-100 pa-20px py-md-30px px-lg-60px",
      staticStyle: {
        "background-color": "rgba(0, 0, 0, 0.5)"
      }
    }, [_c('div', {
      staticClass: "white--text"
    }, [_vm.isOngoing ? _c('v-chip', _vm._b({
      attrs: {
        "x-small": ""
      }
    }, 'v-chip', Object.assign({}, _vm.chip_secondary2), false), [_vm._v("진행중")]) : _c('v-chip', _vm._b({
      attrs: {
        "x-small": ""
      }
    }, 'v-chip', Object.assign({}, _vm.chip_secondary), false), [_vm._v("종료")]), _c('div', {
      staticClass: "tit tit--lg mt-4px mt-md-8px"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.title))]), item !== null && item !== void 0 && item.duration[0] || item !== null && item !== void 0 && item.duration[1] ? _c('div', {
      staticClass: "font-size-14 font-size-md-16 mt-6px mt-md-12px"
    }, [_vm._v(_vm._s(_vm.formatDate(item === null || item === void 0 ? void 0 : item.duration[0])) + " ~ " + _vm._s(_vm.formatDate(item === null || item === void 0 ? void 0 : item.duration[1])))]) : _vm._e()], 1)])]), !_vm.isOngoing ? _c('div', {
      staticClass: "event-card__overlay"
    }, [_c('div', {
      staticClass: "tit white--text"
    }, [_vm._v("이벤트가 종료되었습니다.")])]) : _vm._e()], 1)], 1);
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }