<template>
    <event-page>
        <v-img src="/images/sub/event/tag-event.jpg" class="rounded-lg d-flex text-center align-center">
            <span class="tit tit--lg white--text">모아고 추천상품</span>
        </v-img>

        <div class="product-lists mt-40px mt-lg-60px">
            <div class="product-lists-body">
                <shop-product-items>
                    <shop-product-item v-for="(tag, index) in tags" :key="index" :value="tag.product" lg="3">
                        <template #tag>
                            <v-sheet class="pb-6px pb-md-8px txt txt--sm txt--dark text-center">
                                {{ $dayjs(tag.startsAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(tag.endsAt).format("YYYY-MM-DD") }}
                            </v-sheet>
                        </template>
                    </shop-product-item>
                </shop-product-items>
            </div>
        </div>
    </event-page>
</template>

<script>
import api from "@/api";
import EventPage from "@/components/client/templates/event-page.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";

export default {
    components: {
        EventPage,
        ShopProductItem,
        ShopProductItems
    },
    data: ()=> ({
        tags: [],
        summary: {
            totalCount: 0
        }
    }),
    methods:{
        async init(){
            this.search();
        },
        async search(){
            try{
                let { summary, tags } = await api.v1.shop.tagEvents.gets();
                this.tags = tags;
                this.summary = summary;

            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        this.init()
    }
};
</script>
